@tailwind base;
@tailwind components;
@tailwind utilities;

.goog-te-banner-frame {
  display: none !important;
}

/* @import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Russo+One&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300;400;500;600&family=Grenze:wght@300;400;500&family=Neucha&display=swap');

* {
  font-family: 'Grenze', sans-serif;
  /* font-family: 'Quicksand', Arial; */
}

body {
  background-color: #EBEFF3;
}

.russo {
  font-family: 'Russo One', sans-serif !important;
}

.header {
  position: relative;
  text-align: center;
  background: linear-gradient(60deg, #543ab7 0%, #00acc1 100%);
  color: white;
}

.inner-header {
  height: 60vh;
  width: 100%;
  margin: 0;
  padding: 0;
}

/* .flex {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
} */

.waves {
  position: relative;
  width: 100%;
  height: 15vh;
  margin-bottom: -7px;
  /*Fix for safari gap*/
  min-height: 100px;
  max-height: 150px;
}

.content {
  position: relative;
  height: 20vh;
  text-align: center;
  background-color: #fff;
}

/* Animation */

.parallax>use {
  animation: move-forever 25s cubic-bezier(.55, .5, .45, .5) infinite;
}

.parallax>use:nth-child(1) {
  animation-delay: -2s;
  animation-duration: 7s;
}

.parallax>use:nth-child(2) {
  animation-delay: -3s;
  animation-duration: 10s;
}

.parallax>use:nth-child(3) {
  animation-delay: -4s;
  animation-duration: 13s;
}

.parallax>use:nth-child(4) {
  animation-delay: -5s;
  animation-duration: 20s;
}

@keyframes move-forever {
  0% {
    transform: translate3d(-90px, 0, 0);
  }

  100% {
    transform: translate3d(85px, 0, 0);
  }
}

/*Shrinking for mobile*/
@media (max-width: 768px) {
  .waves {
    height: 40px;
    min-height: 40px;
  }

  .content {
    height: 30vh;
  }

  h1 {
    font-size: 24px;
  }
}

.hexagon {
  -webkit-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
}

.nm-inset {
  background: #edf2f7;
  box-shadow: inset 0.2em 0.2em 0.4em #99b5d2, inset -0.2em -0.2em 0.4em #fff;
}

.nm-flat {
  background: #edf2f7;
  box-shadow: 0.2em 0.2em 0.4em #99b5d2, -0.2em -0.2em 0.4em #fff;
}

.nm-inset-theme {
  background: #31515f;
  box-shadow: inset 0.2em 0.2em 0.4em #afafaf, inset -0.2em -0.2em 0.4em #313131;
  border: none;
}

.nm-flat-theme {
  background: #31515f;
  box-shadow: 0.2em 0.2em 0.4em #313131, -0.2em -0.2em 0.4em #fff;
  border: none;
}

.slider {
  margin: 0 20px;
  overflow: "hidden";
}

.slider img {
  width: 100%;
  border-radius: 10px;
}

.react-multi-carousel-list {
  padding: 0rem 0 2rem 0;
}

.custom-dot-list-style button {
  border: none;
  background: #31515f;
}

.react-multi-carousel-dot.react-multi-carousel-dot--active button {
  background: #31515f !important;
}

.react-multi-carousel-list {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative
}

.react-multi-carousel-track {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: row;
  position: relative;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  will-change: transform, transition
}

.react-multiple-carousel__arrow {
  position: absolute;
  outline: 0;
  transition: all .5s;
  border-radius: 35px;
  z-index: 1000;
  border: 0;
  background: rgba(0, 0, 0, 0.5);
  min-width: 43px;
  min-height: 43px;
  opacity: 1;
  cursor: pointer
}

.react-multiple-carousel__arrow:hover {
  background: rgba(0, 0, 0, 0.8)
}

.react-multiple-carousel__arrow::before {
  font-size: 20px;
  color: #fff;
  display: block;
  font-family: revicons;
  text-align: center;
  z-index: 2;
  position: relative
}

.react-multiple-carousel__arrow:disabled {
  cursor: default;
  background: rgba(0, 0, 0, 0.5)
}

.react-multiple-carousel__arrow--left {
  left: calc(4% + 1px)
}

.react-multiple-carousel__arrow--left::before {
  content: "\e824"
}

.react-multiple-carousel__arrow--right {
  right: calc(4% + 1px)
}

.react-multiple-carousel__arrow--right::before {
  content: "\e825"
}

.react-multi-carousel-dot-list {
  position: absolute;
  bottom: 0;
  display: flex;
  left: 0;
  right: 0;
  justify-content: center;
  margin: auto;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center
}

.react-multi-carousel-dot button {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  opacity: 1;
  padding: 5px 5px 5px 5px;
  box-shadow: none;
  transition: background .5s;
  border-width: 2px;
  border-style: solid;
  border-color: grey;
  padding: 0;
  margin: 0;
  margin-right: 6px;
  outline: 0;
  cursor: pointer
}

.react-multi-carousel-dot button:hover:active {
  background: #080808
}

.react-multi-carousel-dot--active button {
  background: #080808
}

.react-multi-carousel-item {
  transform-style: preserve-3d;
  backface-visibility: hidden
}

@media all and (-ms-high-contrast:none),
(-ms-high-contrast:active) {
  .react-multi-carousel-item {
    flex-shrink: 0 !important
  }

  .react-multi-carousel-track {
    overflow: visible !important
  }
}

[dir='rtl'].react-multi-carousel-list {
  direction: rtl
}

.rtl.react-multiple-carousel__arrow--right {
  right: auto;
  left: calc(4% + 1px)
}

.rtl.react-multiple-carousel__arrow--right::before {
  content: "\e824"
}

.rtl.react-multiple-carousel__arrow--left {
  left: auto;
  right: calc(4% + 1px)
}

.rtl.react-multiple-carousel__arrow--left::before {
  content: "\e825"
}

.random-circle {
  position: absolute;
  width: 200px;
  /* Adjust the size of the circle */
  height: 200px;
  /* Adjust the size of the circle */
  background: #5fc3c336;
  border-radius: 50%;
  z-index: 0;
}

.shadow-neumorphic {
  box-shadow: 6px 6px 12px #9b9ca2, -6px -6px 12px #ffffffad !important;
  border-radius: 10px;
}

.inner-neumorphic {
  box-shadow: inset 4px 4px 4px 0px #9b9ca2a3, -5px -5px 12px #bdbdbd75 !important;
  border-radius: 10px;
}

.main-shadow {
  box-shadow: 0 0 35px 20px #cfcfcf8c;
}

.coins-main ul {
  display: flex;
  margin-top: 20px;
}

.coins-main ul li:hover {
  transform: translateY(-20px);
  transition: .5s;
}

.list-image {
  list-style: none;
  padding: 0;
}

.neumorphic-container {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.neumorphic-item {
  border-radius: 10px;
  background: #f0f0f0;
  padding: 10px;
}

.neumorphic-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

.letter-spacing {
  letter-spacing: 14px;
}

.icon-link {
  display: flex;
  text-decoration: none;
  color: #000;
  border-radius: 9px;
  background: linear-gradient(315deg, #eeeeef, #e2e2e8);
  box-shadow: -1px -1px 5px 0 #FFFFFF, 1px 1px 5px 0 #a1a0a0;
  margin: 10px;
  border: 1px solid #31515f;
}

.icon-link:active {
  box-shadow: inset 1rem 1rem 2rem rgba(0, 0, 0, 0.1), inset -1rem -1rem 2rem rgba(255, 255, 255, 0.5);
}

.outter-shadow {
  border-radius: 16px;
  background: #EBEFF3;
  box-shadow: 4px 4px 8px 0px rgba(189, 194, 199, 0.75), 8px 8px 12px 0px rgba(189, 194, 199, 0.25), -4px -4px 8px 0px rgba(255, 255, 255, 0.75), -8px -8px 12px 0px rgba(255, 255, 255, 0.25);
}

.inner-shadow {
  border-radius: 16px;
  border: 1px solid #F4F7F9;
  background: #EBEFF3;
  box-shadow: 4px 4px 8px 0px rgba(255, 255, 255, 0.75), 2px 2px 8px 0px rgba(189, 194, 199, 0.75) inset;
}

.empty-box {
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  border-radius: 16px;
  background: #EBEFF3;
  box-shadow: 4px 4px 8px 0px rgba(189, 194, 199, 0.75), 8px 8px 12px 0px rgba(189, 194, 199, 0.25), -4px -4px 8px 0px rgba(255, 255, 255, 0.75), -8px -8px 12px 0px rgba(255, 255, 255, 0.25);
  margin-top: 23px;
}

.empty-box-2 {
  width: 100px;
  height: 100px;
  flex-shrink: 0;
  border-radius: 16px;
  background: #EBEFF3;
  box-shadow: 4px 4px 8px 0px rgba(189, 194, 199, 0.75), 8px 8px 12px 0px rgba(189, 194, 199, 0.25), -4px -4px 8px 0px rgba(255, 255, 255, 0.75), -8px -8px 12px 0px rgba(255, 255, 255, 0.25);
}

.img-box {
  width: 120px;
  height: 120px;
  flex-shrink: 0;
  border-radius: 16px;
  background: #EBEFF3;
  box-shadow: 4px 4px 8px 0px rgba(189, 194, 199, 0.75), 8px 8px 12px 0px rgba(189, 194, 199, 0.25), -4px -4px 8px 0px rgba(255, 255, 255, 0.75), -8px -8px 12px 0px rgba(255, 255, 255, 0.25);
}

.live-price {
  border: 1px solid #F4F7F9;
  background: #EBEFF3;
  box-shadow: 4px 4px 8px 0px rgba(255, 255, 255, 0.75), 2px 2px 8px 0px rgba(189, 194, 199, 0.75) inset;
}

.outlined {
  color: #5fc3c300;
  -webkit-text-stroke: 2px #5fc3c3;
}

.btn-gradient {
  background: linear-gradient(93deg, #325260 12.82%, #60C3C3 90.57%);
}

.fin-font {
  color: #292929;
  font-family: Grenze;
  font-size: 192px;
  font-style: normal;
  font-weight: 500;
  line-height: 129px;
  /* 67.188% */
  text-transform: uppercase;
}